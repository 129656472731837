// src/components/Header.js
import React from 'react';
import '../assests/styles/Header.scss';
import logo from '../assests/images/logo.png';

const Header = () => {
    return (
        <header className="header">
            <div className="logo-container">
                <img src={logo} alt="AmongIslam Logo" className="logo" />
            </div>
            <div>
                <h1>AMONGISLAM</h1>
                <h2>LEARN ISLAM AMONG US</h2>
            </div>
            <div className="quote">
                <p>"Indeed with me is my Allah; He will guide me"</p>
                <p className="reference">[ASH-SHU'ARA:62]</p>
            </div>
        </header>
    );
};

export default Header;
