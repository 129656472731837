// src/components/Writers.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Using useNavigate instead of useHistory
import '../assests/styles/Writers.scss';
import HomeIcon from './HomeIcon';
import abdullahsattarImg from '../assests/images/abdullahsattar2.png';
import mahmoodakhtarImg from '../assests/images/mahmoodakhtar.png';
import sampleprofile from '../assests/images/ProfileSample.png';

// Sample writers data
const writers = [
    {
        id: 1,
        name: "Abdullah Sattar",
        //alias: "The Enlightened One",
        blogs: 1,
        stories: 1,
        image: abdullahsattarImg, 
        link: "abdullahsattar",
    },
    {
        id: 2,
        name: "Mahmood Akhtar",
        blogs: 1,
        stories: 0,
        image: mahmoodakhtarImg,
        link: "mahmoodakhtar"
    },
    {
        id: 3,
        name: "Alioua Selma Hind",
        blogs: 0,
        stories: 0,
        image: sampleprofile,
        link: "aliouaselmahind"
    },
];

const Writers = () => {
    const navigate = useNavigate(); // Replacing useHistory with useNavigate

    const handleCardClick = (link) => {
        navigate(`/writers/${link}`);
    };

    return (
        <div>
            <HomeIcon />
            <div className="writers-page">
                <div className='writers-title'>
                    <h1 className="writers-title0">AmongIslam</h1>
                    <h1 className="writers-title1">Writers</h1>
                </div>
                <div className="writers-container">
                    {writers.map(writer => (
                        <div key={writer.link} className="writer-card" onClick={() => handleCardClick(writer.link)}>
                            <img src={writer.image} alt={`${writer.name}'s profile`} className="writer-image" />
                            <div className="writer-info">
                                <h2>{writer.name}</h2>
                                <h3>{writer.alias}</h3>
                                <p>Blogs Published: {writer.blogs}</p>
                                <p>Stories Written: {writer.stories}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Writers;
