// src/components//WriterProfiles/AliouaSelmaHind.js
import React from 'react';
import BackButton from '../BackButton';
import HomeIcon from '../HomeIcon';
import sampleprofile from '../../assests/images/ProfileSample.png';
import './WritersProfiles.scss';

const AliouaSelmaHind = () => {
    return (
        <div>
            <HomeIcon />
            <div className="writer-profile">
                <img src={sampleprofile} alt="Alioua Selma Hind" className="writer-profile-image" />
                <h1>Alioua Selma Hind</h1>
                <div className='Writer-Intro'>
                    <h2>Writer | Literary Critic | Proofreader</h2>
                    <h2>Researcher | Civilization Expert</h2>
                </div>
                <text className='Publish'>Blogs Published: 0</text>
                <text className='Publish'>Stories Written: 0</text>
                <text className='Writer-About'>
                Born and raised in Constantine, Algeria—known as the "City of Bridges"—Selma has dedicated her academic career to exploring the intersections of civilizations, focusing on their cultural, traditional, and religious distinctions. Holding a master's degree in Literature and Civilization, with a specialization in British and American Studies, her thesis, "A Study on Narrative Devices as a Means of Resistance in Mornings in Jenin by Susan Abulhawa," reflects her deep commitment to understanding resistance through literature.<br /><br />
                Since the founding of AmongIslam, Selma has been an integral member, driven by her belief that spreading knowledge and truth is the most powerful way to empower Muslims and unite the Ummah. Her research and insights continue to contribute to the organization's mission of fostering unity and wisdom within the global Muslim community.
                </text>

                <div className='Writer-Back-Button'> <BackButton to="/writers" label="Back to Writers" /> </div>
            </div>
        </div>
    );
};

export default AliouaSelmaHind;
