// src/components//WriterProfiles/MahmoodAkhtar.js
import React from 'react';
import BackButton from '../BackButton';
import HomeIcon from '../HomeIcon';
import mahmoodakhtarImg from '../../assests/images/mahmoodakhtar.png';
import TheMostSuccessfulEducationist from '../../assests/images/TheMostSuccessfulEducationist.jpg';
import TheMostSuccessfulEducationistPDF from '../../assests/PDFs/TheMostSuccessfulEducationist.pdf';
import { FaInstagram, FaYoutube } from 'react-icons/fa';
import './MahmoodAkhtar.scss';
import DownloadButton from '.././DownloadButton';
import ReadOnlineButton from '.././ReadOnlineButton';

const writerblogs = [
    {
        id: 1,
        title: "The Most Successful Educationist",
        writer: "Mahmood Akhtar",
        image: TheMostSuccessfulEducationist,
        fileUrl: TheMostSuccessfulEducationistPDF,
        fileName: "The Most Successful Educationist - AMONGISLAM",
        linkUrl: "https://medium.com/@amongislam/the-most-successful-educationist-5d9ee4a42138",
        instalink: "https://www.instagram.com/p/DAATuBVIJcy/?igsh=MWFpZG1zajNnbDhrdg==",
    },
];

const MahmoodAkhtar = () => {
    return (
        <div>
            <HomeIcon />
            <div className="mahmoodakhtar-writer-profile">
                <img src={mahmoodakhtarImg} alt="Mahmood Akhtar" className="mahmoodakhtar-writer-profile-image" />
                <h1>Mahmood Akhtar</h1>
                <div className='mahmoodakhtar-Writer-Intro'>
                    <h2>Islamic Scholar & Alim-e-Deen</h2>
                    <h2>Author | Professor | Imam</h2>
                </div>
                <div className="mahmoodakhtar-Writer-social-media-icons">
                    <a href="https://www.instagram.com/factfrenzy313/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                    </a>
                    <a href="https://www.youtube.com/@factfrenzy313" target="_blank" rel="noopener noreferrer">
                        <FaYoutube />
                    </a>
                </div>
                <text className='mahmoodakhtar-Publish'>Blogs Published: 1</text>
                <text className='mahmoodakhtar-Publish'>Stories Written: 0</text>
                <text className='mahmoodakhtar-Writer-About'>Raised in the historical region of Bahawalnagar and now residing amidst the skyscrapers of Lahore, Pakistan, Mahmood is a distinguished Hafiz, scholar, Alim-e-Deen, Imam, Ustaad, and professor. With 18 years of Islamic education, including three Master's degrees in Arabic, Islamic Education, and Education Planing & Management with M.Phil. in Islamic Thought & Civilization, Mahmood has become a beacon of knowledge, particularly in the field of Hadith studies. His deep understanding of Islam has transformed countless lives and guided numerous students on their spiritual journeys. <br /><br />Mahmood is the founder of FactFrenzy313, a platform where he shares his wisdom with Muslims across the globe, striving to positively impact society. His kind and patient nature makes him an exemplary preacher of Islam, respected by all who learn from him.</text>

                <div className='mahmoodakhtar-Blogs-Container'>
                    {writerblogs.map((blog) => (
                        <div className='mahmoodakhtar-Blogs-Card' key={blog.id}>
                            <img src={blog.image} alt={`${blog.title}`} className="mahmoodakhtar-Blogs-Image" />
                            <div className="mahmoodakhtar-Blogs-Info">
                                <h2>{blog.title}</h2>
                                {/* <h3>Written by: {blog.writer}</h3> */}
                                <div className='mahmoodakhtar-Blog-Buttons'>
                                <DownloadButton
                                    fileUrl={blog.fileUrl}
                                    fileName={blog.fileName}
                                />
                                <ReadOnlineButton
                                    linkUrl={blog.linkUrl}
                                />
                                <a className="mahmoodakhtar-blog-insta-icon" href={blog.instalink} target="_blank" rel="noopener noreferrer">
                                    <FaInstagram />
                                </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <BackButton to="/writers" label="Back to Writers" />
            </div>
        </div>
    );
};

export default MahmoodAkhtar;
