import React, { useState, useRef } from 'react';
import '../assests/styles/Contact.scss';
import HomeIcon from './HomeIcon';
import Header from './Header';
import emailjs from 'emailjs-com';

const Contact = () => {
    const form = useRef();
    const [messageSent, setMessageSent] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_cm3pqpy', 'template_pqfnsme', form.current, 'fLqBIuNcpTtY1JM9V')
            .then(() => {
                setMessageSent(true); // Show confirmation message
                e.target.reset();
                setTimeout(() => setMessageSent(false), 5000); // Hide the message after 5 seconds
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
            });
    };

    return (
        <div>
            <HomeIcon />
            <div className='Contact-Container'>
                <div className='Contact-Header'>
                    <Header />
                </div>
                <div className='Contact-Form'>
                    <form ref={form} onSubmit={sendEmail} className='contact_form'>
                        <input type='text' name='name' placeholder='Your Blessed Full Name' required />
                        <input type='email' name='email' placeholder='Your Email' required />
                        <textarea name='message' rows="7" placeholder='Your Message' required />
                        <button type='submit' className='btn btn-primary'>Send Message</button>
                    </form>
                    {messageSent && <p className='confirmation-message'>JAZAKALLAH! Your message has been sent successfully.</p>}
                </div>
            </div>
        </div>
    );
};

export default Contact;
