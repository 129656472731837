// src/components/BackButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../assests/styles/BackButton.scss';

const BackButton = ({ to = '/', label = 'Back' }) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(to);
    };

    return (
        <button onClick={handleBackClick} className="back-button">
            ← {label}
        </button>
    );
};

export default BackButton;


// For reuse 

// import BackButton from './BackButton';

// const SomeOtherComponent = () => {
//     return (
//         <div>
//             <BackButton to="/" label="Back to Home" />
//             {/* Other content */}
//         </div>
//     );
// };
