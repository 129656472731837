// src/components/Stories.js
import React from 'react';
import '../assests/styles/Stories.scss';
import HomeIcon from './HomeIcon';
import IbnSinasTwoLambs from '../assests/images/IbnSinasTwoLambs.jpg';
import IbnSinasTwoLambsPDF from "../assests/PDFs/IbnSinasTwoLambs.pdf";
import DownloadButton from './DownloadButton';
import ReadOnlineButton from './ReadOnlineButton';
import { FaInstagram } from 'react-icons/fa';

// Sample stories data
const stories = [
    {
        id: 1,
        title: "Ibn Sina's Two Lambs",
        writer: "Abdullah Sattar",
        image: IbnSinasTwoLambs,
        fileUrl: IbnSinasTwoLambsPDF,
        fileName: "Ibn Sina's Two Lambs - AMONGISLAM",
        linkUrl: "https://medium.com/@amongislam/ibn-sinas-two-lambs-43a76648438b",
        instalink: "https://www.instagram.com/p/C__DYD-IIuJ/?igsh=YzNreWZqcTg5ZWdq",
    },
];

const Stories = () => {
    return (
        <div>
            <HomeIcon />
            <div className='Stories-Page'>
                <div className='Stories-Title'>
                    <h1 className="Stories-Title0">AmongIslam</h1>
                    <h1 className="Stories-Title1">Stories</h1>
                </div>
                <div className='Stories-Container'>
                    {stories.map((Story) => (
                        <div className='Stories-Card' key={Story.id}>
                            <img src={Story.image} alt={`${Story.title}`} className="Stories-Image" />
                            <div className="Stories-Info">
                                <h2>{Story.title}</h2>
                                <h3>Written by: {Story.writer}</h3>
                                <div className='Stories-Buttons'>
                                <DownloadButton
                                    fileUrl={Story.fileUrl}
                                    fileName={Story.fileName}
                                />
                                <ReadOnlineButton
                                    linkUrl={Story.linkUrl}
                                />
                                <a className="Story-insta-icon" href={Story.instalink} target="_blank" rel="noopener noreferrer">
                                    <FaInstagram />
                                </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Stories;
