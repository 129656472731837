import React from 'react';
import Header from './Header';
import NavigationMenu from './NavigationMenu';
import '../assests/styles/Home.scss';

const Home = () => {
    return (
        <div className="home-page">
            <div className="content-wrapper">
                <Header />
                <NavigationMenu />
            </div>
        </div>
    );
};

export default Home;
