import React from 'react';
import '../assests/styles/DownloadButton.scss';

const DownloadButton = ({ fileUrl, fileName }) => {
  // Handle file download
  const handleDownload = () => {
    const fullFileName = `${fileName}.pdf`; // Only use the provided fileName without random junk
    
    // Create a link element for download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fullFileName); // Set the download attribute with the custom name
    
    // Trigger the download
    document.body.appendChild(link);
    link.click();
    
    // Cleanup the link element
    link.parentNode.removeChild(link);
  };

  return (
    <button onClick={handleDownload} className="download-button">
      Download
    </button>
  );
};

export default DownloadButton;
