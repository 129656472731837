// src/components/ReadOnlineButton.js
import React from 'react';
import '../assests/styles/ReadOnlineButton.scss';

const ReadOnlineButton = ({ linkUrl }) => {
  // Function to handle redirection to the provided online link
  const handleReadOnline = () => {
    window.open(linkUrl, '_blank');  // Open the link in a new tab
  };

  return (
    <button onClick={handleReadOnline} className="read-online-button">
      Read Online
    </button>
  );
};

export default ReadOnlineButton;
