// src/components/HomeIcon.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import '../assests/styles/HomeIcon.scss';

const HomeIcon = () => {
    return (
        <div className="home-icon">
            <Link to="/">
                <FaHome />
            </Link>
        </div>
    );
};

export default HomeIcon;
