// src/components/Footer.js
import React from 'react';
import '../assests/styles/Footer.scss';
import { FaInstagram, FaMedium, FaYoutube, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
    return (
        <nav className="Footer-menu">
            <div className="social-media-icons">
                <a href="https://www.instagram.com/amongislamorg/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                </a>
                <a href="https://medium.com/@amongislam" target="_blank" rel="noopener noreferrer">
                    <FaMedium />
                </a>
                <a href="https://www.youtube.com/@amongislam" target="_blank" rel="noopener noreferrer">
                    <FaYoutube />
                </a>
                <a href="https://www.linkedin.com/company/amongislam" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin />
                </a>
            </div>
            <div className="copyright">
                <p>Copyright © 2023-2024 AmongIslam™, All rights reserved.</p>
            </div>
        </nav>
    );
};

export default Footer;
